import {
  ChangeDetectorRef,
  Component,
  Injectable,
  OnDestroy,
} from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../../store/app.reducer";
import * as IntakeSearchActions from "@pr-caseworker/app/intake-module/store/searchTerms/searchTerms.actions";
import { RouteLocalizerService } from "../../../routing/route-localizer.service";
import {
  Case,
  CaseForBulkAssignAPILoad,
  CommCert,
  DisplayedCase,
} from "../../../core/cases-module/models/case.model";
import { CasesService } from "../../../core/cases-module/cases.service";
import { LobService } from "../../../../../../lib/src/lib/services/lob/lob.service";
import {
  AlertService,
  FormSelectOption,
  InterFormsService,
  LovService,
} from "lib";
import { TranslateService } from "@ngx-translate/core";
import { UntypedFormBuilder, FormControl, FormGroup } from "@angular/forms";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { User } from "@pr-caseworker/app/core/auth-module/store/auth.reducer";
import { FormAutoCompleteOption } from "projects/lib/src/public-api";
import { intakeSearchTerms } from "../../store/intake-state";
import { NGXLogger } from "ngx-logger";
import { Logger } from "@aws-amplify/core";
import { AppFlow } from "@pr-caseworker/app/core/models/common.model";
import { distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "prcw-case-list-table",
  templateUrl: "./case-list-table.component.html",
  styleUrls: ["./case-list-table.component.scss"],
})
@Injectable()
export class CaseListTableComponent implements OnDestroy {
  public cases?: DisplayedCase[] = [];
  public lobFilterValue: string | null;
  public classFilterValue: string | null;
  public statusFilterValue: number | null;
  public assignmentFilterValue: boolean | null;
  public searchTerm: string;
  public searchByValue: number;
  public cognitoCaseWorkerId: string | null;
  public paginationNumber: number;
  public numberOfCases: number;
  public defaultPageToView: number = 0;
  public pageToView: number;
  public showFilter: string;
  public defaultSortOrder: string = "submittedAt ASC";
  public sortOrder: string = this.defaultSortOrder;
  public commCertFilter = new FormControl(null);
  public commCerts: CommCert[] = [];
  public commCertOptions: FormSelectOption[] = [];
  private categoryIdsWithRequiredClass: Array<number> = [
    12, 17, 18, 53, 1000, 1001, 1002, 1003, 1004,
  ];
  public showclassFilter: boolean = false;
  public copyKeyValue: string =
    "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CLASS_SEARCH";
  public isLoadingCases = false;
  public bulkAssignTo: FormControl;
  public bulkAssignmentActive: boolean;
  public caseListPageScope = "CASE_LIST_PAGE";
  public currentUser: User;
  private subscriptionsList: Subscription[] = [];

  public assignedToMe: FormControl;
  public noApplicationNumber: FormControl;
  private unassignConst = "UNASSIGN";
  public assignToList: FormAutoCompleteOption[] = [
    {
      value: this.unassignConst,
      text: "INTAKE.CASE_LIST_PAGE.OPTIONS_UNASSIGNED",
    },
  ];
  public singleCaseworkerOption: FormAutoCompleteOption[];
  public assignToGroupForm: FormGroup;

  public bulkAssignAll = false;
  public bulkAssignmentDisabled = true;
  private startTime = performance.now();

  appFlow = AppFlow;

  constructor(
    public routeLocalizer: RouteLocalizerService,
    private store: Store<fromApp.State>,
    private caseService: CasesService,
    public lobService: LobService,
    private alertService: AlertService,
    public interFormSvc: InterFormsService,
    public translate: TranslateService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private logger: NGXLogger
  ) {
    this.init();
  }

  /* PUBLIC --------------------------------------------------------------------------------- */

  public get lang(): string {
    return this.routeLocalizer.getCurrentRouteLang();
  }

  // onInit doesn't work with Injectable, so we "diy" it
  init(): void {
    this.isLoadingCases = true;
    this.assignedToMe = new FormControl(false);
    this.noApplicationNumber = new FormControl(false);
    this.bulkAssignTo = new FormControl({ value: null, disabled: false });
    const { paginationLimit } = this.caseService.defaults.intake;
    this.paginationNumber = Math.ceil(this.numberOfCases / paginationLimit);
    this.subscriptionsList.push(
      this.store
        .select("auth")
        .pipe(map((authState) => authState?.user))
        .subscribe((user) => {
          if (user && user.usersList) {
            // const userListTime = performance.now();
            // console.log(`Get User List time : ${userListTime - this.startTime} milliseconds`);
            this.currentUser = user ? _.cloneDeep(user) : ({} as User);
            this.singleCaseworkerOption = [
              {
                value: user.username,
                text: user.username?.toString() || "",
              },
            ];

            const userList = user.usersList.filter((item) => {
              if (user.userPool?.toLocaleUpperCase() === "TEAMLEADS") {
                return true;
              } else {
                return (
                  item.userPool?.toLocaleUpperCase() === "TEAMLEADS" ||
                  user.username === item.username
                );
              }
            });
            if (userList) {
              userList.forEach((item) => {
                this.assignToList.push({
                  value: item.username,
                  text: item.username || "",
                });
              });
            }
            this.subscribeForSearchTermsAndGetData();
          }
        })
    );
    this.caseService
      .getCommCerts()
      .pipe(take(1))
      .subscribe((commCerts) => {
        this.commCerts = commCerts;
      });
  }

  makeCasesUiReady(casesList: Case[]) {
    const assignmentControls: any = {};
    casesList.forEach((cse, index) => {
      if (cse.cognitoCaseWorkerId) {
        assignmentControls[cse.id] = {
          value: cse.cognitoCaseWorkerId,
          text: cse.cognitoCaseWorkerId,
        };
      } else {
        assignmentControls[cse.id] = { value: null };
      }
      this.updateCaseStatus(cse);
    });
    this.assignToGroupForm = this.fb.group(assignmentControls);
    this.bulkAssignTo?.disable();
    this.bulkAssignmentDisabled = true;
    this.cdr.detectChanges();
  }

  public clearFilters(): void {
    this.classFilterValue = null;
    this.store.dispatch(
      new IntakeSearchActions.ResetAllFilters(this.sortOrder)
    );
  }

  lobFiltered($value: string) {
    this.isLoadingCases = true;
    this.shouldShowClassFilter($value);
    this.pageToView = 0;
    this.store.dispatch(
      new IntakeSearchActions.SetLobFilterAndClassFilterValue($value, null)
    );
  }

  statusFiltered($value: number) {
    this.isLoadingCases = true;
    this.pageToView = 0;
    this.store.dispatch(new IntakeSearchActions.SetStatusFilterValue($value));
  }

  classFiltered($value: boolean) {
    this.isLoadingCases = true;
    this.pageToView = 0;
  }

  assignmentFilter($value: boolean) {
    this.isLoadingCases = true;
    this.pageToView = 0;
    this.store.dispatch(new IntakeSearchActions.SetAssignedFilterValue($value));
  }

  public checkedPageNumber($event: number) {
    this.isLoadingCases = true;
    this.pageToView = $event;
    this.store.dispatch(
      new IntakeSearchActions.SetPageToReview(this.pageToView)
    );
    this.scrollToTable();
  }

  //  Setting pill status
  private updateCaseStatus(application: DisplayedCase) {
    if (application?.caseStatusId.toString() === "7") {
      application.statusType = "ready7";
      application.statusName = this.translate.instant(
        "INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.READY_FOR_REVIEW_LABEL"
      );
    } else if (application?.caseStatusId.toString() === "11") {
      application.statusType = "rpa11";
      application.statusName = this.translate.instant(
        "INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.RPA_UPLOAD_FAIL_LABEL"
      );
    } else if (application?.caseStatusId.toString() === "12") {
      application.statusType = "upload12";
      application.statusName = this.translate.instant(
        "INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.READY_FOR_SUPPORT_DOC_UPLOAD_LABEL"
      );
    } else if (application?.caseStatusId.toString() === "13") {
      application.statusType = "failed13";
      application.statusName = this.translate.instant(
        "INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.SUPPORTING_DOC_UPLOAD_FAILED_LABEL"
      );
    } else if (application?.caseStatusId.toString() === "14") {
      application.statusType = "check14";
      application.statusName = this.translate.instant(
        "INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.READY_FOR_CHECK_LABEL"
      );
    } else if (application?.caseStatusId.toString() === "8") {
      application.statusType = "returned8";
      application.statusName = this.translate.instant(
        "INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.RETURNED_LABEL"
      );
    } else if (application?.caseStatusId.toString() === "10") {
      application.statusType = "complete10";
      application.statusName = this.translate.instant(
        "INTAKE.CASE_LIST_PAGE.CASES_TABLE.PILL_STATUS.COMPLETED_LABEL"
      );
    }

    application.isCaseAssignable = this.isCaseAssignable(application);
  }

  private getSearchValue() {
    if (this.searchByValue === 0 && this.searchTerm) {
      return {
        value: this.searchTerm,
        fields: this.caseService.defaults.intake.search,
      };
    } else if (this.searchByValue === 2 && this.searchTerm) {
      return {
        value: this.searchTerm,
        fields: this.caseService.defaults.intake.application,
      };
    }

    return undefined;
  }

  //check if fliteredlob is a category, if it is, search for all lob ID's with that category ID
  private getFilterValue() {
    const parsedLobId = this.lobFilterValue
      ? parseInt(this.lobFilterValue, 10)
      : null;

    let res: any = {
      ...this.caseService.defaults.intake.filters,
      lobId: parsedLobId,
      isAssigned: this.assignmentFilterValue,
      referralOrganization: null,
      designatedEntity: null,
      commCertId: null,
    };

    //Check if category dropdown has been selected
    if (parsedLobId) {
      //check if the category lob has subcategories and query for the group of subcategories
      if (this.lobService.fetchSubcategory(parsedLobId).length > 0) {
        const subcategoryLobIds: number[] = [];
        this.lobService.fetchSubcategory(parsedLobId).forEach((subcategory) => {
          subcategoryLobIds.push(subcategory.id);
        });
        res.lobId = subcategoryLobIds;
      }
      //check if the subcategory dropdown has been selected
      if (this.classFilterValue) {
        //Check if it's GARS
        if (parsedLobId === 53) {
          //Add the referal Partner type to the call
          res.referralOrganization = +this.classFilterValue;
        } else if (parsedLobId === 12) {
          res.designatedEntity = +this.classFilterValue;
        } else {
          res.lobId = this.classFilterValue;
        }
      }
      if (parsedLobId === 12) {
        res.commCertId = this.commCertFilter.value;
      }
    }
    //check if the case status dropdown has been selected
    if (this.statusFilterValue) {
      res.caseStatusId = this.statusFilterValue;
    }
    return res;
  }

  // Uses variables to update API request. Called on sort, pagination, and filter(s)
  public async changePageAndFilter(): Promise<void> {
    const filterValue = this.getFilterValue();
    const searchValue = this.getSearchValue();
    this.isLoadingCases = true;
    try {
      const { paginationLimit } = this.caseService.defaults.intake;
      const searchByCaseWorkerValue = this.cognitoCaseWorkerId;
      const hasNoApplicationNumber = this.noApplicationNumber.value;
      // const apiCallStartTime = performance.now();
      // console.log(`Time elapsed before make API call : ${apiCallStartTime - this.startTime} milliseconds`);
      if (!this.lobService.lobs) await this.lobService.updateLobs(); // needed for proper categoryText to show up in list
      const filteredResults = await this.caseService.getAllCases(
        filterValue,
        this.pageToView || this.defaultPageToView,
        this.sortOrder || this.defaultSortOrder,
        searchValue,
        searchByCaseWorkerValue,
        hasNoApplicationNumber
      );
      this.logger.log("Seacrh Results from API is:", filteredResults);
      this.logger.log("Cases found for your search:", filteredResults[0].cases);
      this.logger.log(
        "Number of cases as per API response is:",
        filteredResults[1]
      );
      if (this.searchByValue === 2 && this.searchTerm) {
        let startsWithSearchValue = [];
        startsWithSearchValue = filteredResults[0].cases.filter(
          (appName: any) =>
            appName.applicationNumber.startsWith(this.searchTerm)
        );
        this.cases = startsWithSearchValue;
      } else {
        this.cases = filteredResults[0].cases;
      }
      const apiCallEndTime = performance.now();
      // console.log(`API time to get cases in caseService.getAllCases: ${apiCallEndTime - apiCallStartTime} milliseconds`);
      this.bulkAssignAll = false;
      if (this.cases) {
        this.makeCasesUiReady(this.cases);
      }
      const endTime = performance.now();
      // console.log(`Time to get cases UI ready took: ${endTime - this.startTime} milliseconds`);
      this.numberOfCases = filteredResults[1];
      this.paginationNumber = Math.ceil(this.numberOfCases / paginationLimit);
      this.isLoadingCases = false;
    } catch (error) {
      this.isLoadingCases = false;
      console.error("Error trying to get cases - caseService.getAllCases");
      this.alertService.danger(this.alertTechnicalError);
    }
  }

  public setViewLabel(email: string) {
    const prefix = {
      en: "View ",
      fr: "Vue ",
    };
    return prefix[this.lang as "en" | "fr"] + email;
  }

  public sort(field: string, order: string) {
    this.isLoadingCases = true;
    this.sortOrder = `${field} ${order}`;
    this.store.dispatch(new IntakeSearchActions.SetSortValue(this.sortOrder));
    this.changePageAndFilter();
  }

  public formatDate(field: string) {
    return field?.replace(/\s/, "T");
  }

  public scrollToTable() {
    window.scroll(0, 150);
  }

  /* PRIVATE --------------------------------------------------------------------------------- */

  /*
   This is where you subscribe to store for terms
   and refresh the list of cases when search params are changed
  */
  private subscribeForSearchTermsAndGetData(): void {
    this.commCertFilter.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(
          (value: any) =>
            value == null || (!!value && !value.toString().includes(":"))
        )
      )
      .subscribe((value) => {
        this.store.dispatch(
          new IntakeSearchActions.SetSubClassFilterValue(value)
        );
      });
    this.subscriptionsList.push(
      this.store
        .select(intakeSearchTerms)
        .pipe(distinctUntilChanged())
        .subscribe((searchTerms) => {
          this.lobFilterValue = searchTerms?.lobFilterValue;
          this.statusFilterValue = searchTerms?.statusFilterValue;
          this.assignmentFilterValue = searchTerms?.assignedFilterValue;
          this.sortOrder = searchTerms?.sortOrder;
          if (this.lobFilterValue) {
            this.classFilterValue = searchTerms?.classFilterValue;
            this.shouldShowClassFilter(this.lobFilterValue);
          }
          this.pageToView = searchTerms?.pageToReview;

          this.noApplicationNumber.setValue(searchTerms?.noApplicationNumber);
          this.assignedToMe.setValue(searchTerms?.assignedToMe);
          if (searchTerms?.assignedToMe) {
            this.cognitoCaseWorkerId = this.currentUser.username as string;
          }
          this.searchByValue = searchTerms?.searchBy;
          this.searchTerm = searchTerms?.searchTerm as string;
          if (this.searchByValue === 1 && !this.assignedToMe.value) {
            this.cognitoCaseWorkerId = searchTerms?.searchTerm as string;
          } else if (
            (this.searchByValue === 0 &&
              !searchTerms?.searchTerm &&
              !searchTerms.assignedToMe) ||
            (this.searchByValue === 0 && !searchTerms.assignedToMe)
          ) {
            this.cognitoCaseWorkerId = null;
          } else if (
            (this.searchByValue === 2 &&
              !searchTerms?.searchTerm &&
              !searchTerms.assignedToMe) ||
            (this.searchByValue === 0 && !searchTerms.assignedToMe)
          ) {
            this.cognitoCaseWorkerId = null;
          }
          this.changePageAndFilter();
        })
    );
  }

  private get alertTechnicalError(): string {
    return this.translate.instant("CASE_DETAILS.ALERTS.TECHNICAL_ERROR");
  }

  private get bulkTechnicalError(): string {
    return this.translate.instant(
      "INTAKE.CASE_LIST_PAGE.BULK_ASSIGN_TECHNICAL_ERROR"
    );
  }

  public get bulkAssignmentLabel(): string {
    return this.bulkAssignmentActive
      ? this.lang === "fr"
        ? "Tout désélectionner"
        : "Deselect all"
      : this.lang === "fr"
      ? "Tout sélectionner"
      : "Select all";
  }

  public get ay18AssignToLabel(): string {
    return this.lang === "fr" ? "Sélectionnez" : "Select";
  }

  private shouldShowClassFilter(lobfilterValue: string) {
    if (
      this.categoryIdsWithRequiredClass.includes(parseInt(lobfilterValue, 10))
    ) {
      this.showclassFilter = true;
      if (lobfilterValue === "53") {
        this.copyKeyValue =
          "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.REFERRAL_PARTNER_FILTER";
      } else if (lobfilterValue === "12") {
        this.copyKeyValue =
          "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.DESIGNATED_ENTITY_FILTER";
      } else {
        this.copyKeyValue = "INTAKE.CASE_LIST_PAGE.TABLE_HEADINGS.CLASS_SEARCH";
      }
    } else {
      this.showclassFilter = false;
      this.classFilterValue = null;
    }
    console.log("getting comm certs");
    this.caseService
      .getCommCerts()
      .pipe(take(1))
      .subscribe((commCerts) => {
        this.commCerts = commCerts;
        let relevantCommCerts = commCerts;
        if (this.classFilterValue)
          relevantCommCerts = this.commCerts.filter(
            (cert: CommCert) =>
              cert.designatedEntityId === +this.classFilterValue!
          );
        this.commCertOptions = relevantCommCerts.map((cert: CommCert) => {
          return {
            value: cert.id,
            text: { en: cert.commCert, fr: cert.commCert },
          };
        });

        if (
          !this.commCertOptions.some(
            (cert: any) => cert.value === this.commCertFilter.value
          )
        )
          this.commCertFilter.setValue(null);
      });
  }

  public get hasSomeSelectedForBulkAssign(): boolean {
    if (this.cases?.find((item) => item.bulkAssigned)) {
      return true;
    } else {
      return false;
    }
  }
  public toggleBulkAssign(rowCase: Case): void {
    rowCase.bulkAssigned = !rowCase.bulkAssigned;
    this.bulkAssignToSetState();
  }

  public toggleBulkAssignAll(value: boolean): void {
    this.bulkAssignmentActive = value;
    this.bulkAssignAll = value;
    this.cases?.forEach((item) => {
      if (this.isCaseAssignable(item)) {
        item.bulkAssigned = value;
      }
    });
    this.bulkAssignToSetState();
  }

  bulkAssignToSetState(): void {
    if (this.cases?.find((item) => item.bulkAssigned)) {
      this.bulkAssignTo.enable();
      this.bulkAssignmentDisabled = false;
    } else {
      this.bulkAssignTo.disable();
      this.bulkAssignmentDisabled = true;
    }
  }

  public doBulkAssignment(): void {
    if (this.bulkAssignTo.value.value === null) {
      return;
    }
    const bulkAssignCases = this.cases?.filter((c) => c.bulkAssigned);
    bulkAssignCases?.forEach((item) => (item.assignError = undefined));
    if (bulkAssignCases?.length === 0) {
      return;
    } else {
      this.interFormSvc.startSpinner("BULK-ASSIGN", this.caseListPageScope);
      // assign to null if unnsigned has been selected
      const newCognitoCaseWorkerId =
        this.bulkAssignTo.value.value !== this.unassignConst
          ? this.bulkAssignTo.value.value
          : null;
      const casesForAPI: CaseForBulkAssignAPILoad[] = [];
      bulkAssignCases?.forEach((item) =>
        casesForAPI.push({
          cognitoCaseWorkerId: item.cognitoCaseWorkerId,
          id: item.id,
        })
      );
      // this method is in progress ....
      const bodyForAPI = {
        newCognitoCaseWorkerId,
        cases: casesForAPI,
      };
      this.caseService
        .bulkAssignIntakeRenewalCases(bodyForAPI)
        .then((data) => {
          if (data) {
            data.forEach((item) => {
              const caseInList = this.cases?.find(
                (c) => c.id === item.id
              ) as Case;
              if (item.assignError) {
                //  add delay so that the error is always read out by screen reader
                //  otherwise it is cut off by the screen reader reading out "Assign to select"
                setTimeout(() => {
                  caseInList.assignError = item.assignError;
                }, 200);
              } else {
                caseInList.cognitoCaseWorkerId = item.cognitoCaseWorkerId;
                caseInList.status.id = item.caseStatusId.toString();
                caseInList.caseStatusId = item.caseStatusId;
                this.assignToGroupForm.controls[caseInList.id].setValue({
                  value: item.cognitoCaseWorkerId,
                  text: item.cognitoCaseWorkerId,
                });
                this.updateCaseStatus(caseInList);
              }
            });
          }
          bulkAssignCases?.forEach((item) => (item.bulkAssigned = false));
          this.bulkAssignAll = false;
          this.bulkAssignTo.setValue({ value: null });
          this.bulkAssignToSetState();
          this.interFormSvc.stopSpinner("BULK-ASSIGN");
          // this.alertService.success('Bulk assigned successful');
        })
        .catch(() => {
          this.interFormSvc.stopSpinner("BULK-ASSIGN");
          // bulkAssignCases?.forEach(item => item.bulkAssigned = false);
          this.alertService.danger(this.bulkTechnicalError);
        });
    }
  }

  public onSelectBulkAssigned(event: any): void {
    // to be used evetualy or removed...
  }

  public assignByCase(assignTo: any, caseToAssign: DisplayedCase): void {
    if (
      !assignTo.value ||
      assignTo.value === caseToAssign.cognitoCaseWorkerId
    ) {
      return;
    }
    const newCognitoCaseWorkerId =
      assignTo.value !== this.unassignConst ? assignTo.value : null;
    const self = this;
    // for efficiency we might put this in place
    // if (!caseToAssign.cognitoCaseWorkerId && !newCognitoCaseWorkerId) {
    //   self.assignToGroupForm.controls[caseToAssign.id].setValue({ value: null});
    //   return;
    // }
    caseToAssign.isUpdating = true;
    caseToAssign.assignError = undefined;

    const cases = [
      {
        cognitoCaseWorkerId: caseToAssign.cognitoCaseWorkerId,
        id: caseToAssign.id,
      },
    ];
    const bodyForAPI = {
      newCognitoCaseWorkerId,
      cases,
    };
    this.caseService
      .bulkAssignIntakeRenewalCases(bodyForAPI)
      .then((data) => {
        caseToAssign.isUpdating = false;
        if (data) {
          data.forEach((item) => {
            if (item.assignError) {
              self.assignToGroupForm.controls[caseToAssign.id].setValue({
                value: caseToAssign.cognitoCaseWorkerId,
                text: caseToAssign.cognitoCaseWorkerId,
              });
              this.alertService.danger(this.alertTechnicalError);
            } else {
              caseToAssign.cognitoCaseWorkerId = item.cognitoCaseWorkerId;
              self.assignToGroupForm.controls[caseToAssign.id].setValue({
                value: caseToAssign.cognitoCaseWorkerId,
                text: caseToAssign.cognitoCaseWorkerId,
              });
              caseToAssign.status.id = item.caseStatusId.toString();
              caseToAssign.caseStatusId = item.caseStatusId;
              this.updateCaseStatus(caseToAssign);
            }
          });
        }
      })
      .catch(() => {
        caseToAssign.isUpdating = false;
        self.assignToGroupForm.controls[caseToAssign.id].setValue({
          value: caseToAssign.cognitoCaseWorkerId,
          text: caseToAssign.cognitoCaseWorkerId,
        });
        self.alertService.danger(this.bulkTechnicalError);
      });
  }

  isCaseAssignable(itemCase: Case): boolean {
    if (this.currentUser.userPool?.toLocaleUpperCase() === "CASEWORKERS") {
      //  if assigned to me or unassigned
      //  and not returned or completed
      if (
        (this.currentUser.username === itemCase.cognitoCaseWorkerId ||
          itemCase.cognitoCaseWorkerId === null) &&
        itemCase.status.id.toString() !== "8" &&
        itemCase.status.id.toString() !== "10"
      ) {
        return true;
      }
    } else if (this.currentUser.userPool?.toLocaleUpperCase() === "TEAMLEADS") {
      if (
        itemCase.status.id.toString() !== "10" &&
        itemCase.status.id.toString() !== "8"
      ) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptionsList.forEach((subsc) => subsc.unsubscribe());
  }

  assignedToMeClicked(currentValue: boolean) {
    this.isLoadingCases = true;
    setTimeout(() => {
      if (currentValue) {
        this.cognitoCaseWorkerId = null;
        this.store.dispatch(new IntakeSearchActions.SetAssignedToMe(false));
      } else {
        this.pageToView = 0;
        this.cognitoCaseWorkerId = this.currentUser?.username as string;
        this.store.dispatch(new IntakeSearchActions.SetAssignedToMe(true));
      }
    }, 100);
  }

  noApplicationNumberClicked() {
    this.isLoadingCases = true;
    setTimeout(() => {
      const theValue = this.noApplicationNumber.value;
      if (theValue) {
        this.store.dispatch(
          new IntakeSearchActions.SetNoApplicationNumber(true)
        );
      } else {
        this.pageToView = 0;
        this.store.dispatch(
          new IntakeSearchActions.SetNoApplicationNumber(false)
        );
      }
    }, 100);
  }

  public get placeHolderWithParam(): string {
    return this.translate.instant(
      "INTAKE.CASE_LIST_PAGE.OPTIONS_ASSIGN_TO_WITH_PARAM",
      { optionsNbr: this.cases?.filter((i) => i.bulkAssigned)?.length }
    );
  }

  sortAssignToList(
    selectedValue: string,
    options: FormAutoCompleteOption[]
  ): FormAutoCompleteOption[] {
    if (
      !this.currentUser ||
      !this.currentUser.username ||
      selectedValue === this.currentUser.username
    ) {
      return options;
    }
    return options.sort((o1, o2) => {
      if (o1.value === this.currentUser.username) {
        return -1;
      } else if (o2.value === this.currentUser.username) {
        return 1;
      }
      return 0;
    });
  }
  public trackElemnt(index: number, element: any): any {
    return element ? element.id : null;
  }
}
